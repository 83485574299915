import { graphql } from "gatsby"

export { Post as default } from "@views"

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    strapiAuthor {
      name
    }
    strapiArticle(slug: { eq: $slug }) {
      strapiId
      title
      description
      content
      published_at
      updated_at
      banner {
        url
      }
    }
  }
`
